import React from "react"
import Player from "../../../blocks/en/player"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  player: playersYaml(slug: { eq: "glushkov" }) {
    ...PlayerInfoEn
  }
  stats: allStatsYaml {
    nodes {
      key
      value_en
      average
    }
  }
}`

export default class Glushkov extends Player {
  renderDescription() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>
          An attacking player preferring to play on the left flank. Has good dribbling skills, is able to pick up good speed with the ball. Always looking to create danger in attack..</p>
          <p>A team player, is confident in interacting with his partners during the attack. Able to move into space and has good off-the-ball movement.</p>
          <p>Helps his team in defense, trying to recover the ball the opponent's half of the field. Confidently serves set pieces.</p>
          <p>Has a stable passing level, is comfortable with one-touch moves. Effective finishing in goalscoring opportunities.</p>
          <p>In the fall of 2018 scored 7 goals in 8 matches for Ural II.</p>
          <p>An alumnus of Dynamo Moscow Academy.</p>
        </div>
      </div>
    )
  }
}
